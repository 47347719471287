import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { BlogData } from "../BlogComponent/BlogData";
import { HeaderData } from "../Header/HeaderData";
import { MenuItemData } from "../MenuItem/MenuItemData";

const BannerGlobal = () => {
  const [heading, setHeading] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  let pathName = location.pathname;
  console.log(pathName);

  useEffect(() => {
    const pathnamee = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnamee.map((e, i) => ({
      title: e,
      path: `/${pathnamee.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      if (pathName.includes("blog-details")) {
        const bData = BlogData.find((e) => e.id == id);
        setHeading(bData.name);
      }
      if (pathName.includes("menu-details")) {
        const mData = MenuItemData.find((e) => e.id == id);
        setHeading(mData.name);
      }
    } else {
      const data = HeaderData.find((data) => {
        if (data?.subNav) {
          const subTitel = data?.subNav?.find((s) => s.path === pathName);
          if (subTitel) {
            return data;
          }
        } else if (data.path === pathName) {
          return data;
        }
      });

      if (data?.subNav) {
        const obj = data?.subNav.find((s) => s.path === pathName);
        setHeading(obj.heading);
      } else {
        setHeading(data.heading);
      }
    }
  }, []);

  return (
    <>
      <section className="About_hero_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="about_content">
                <h2>{heading} </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb-ss">
         <nav>
         <ol class="breadcrumb">
            <Link to="/" className="breadcrumb-item">
              Home
            </Link>
            {breadcrumbs.map((breadcrumb, index) => (
              <li
                className={`breadcrumb-item${
                  index === breadcrumbs.length - 1 ? " current" : ""
                } ${breadcrumb.path === "/faq" && "item-uppercase"}`}
              >
                <Link key={index} to={breadcrumb.path}>
                  {breadcrumb.title.replace(/-/g, " ")}
                </Link>
              </li>
            ))}
          </ol>
         </nav>
        </div>
      </section>
    </>
  );
};

export default BannerGlobal;
