import React from "react";
import Header from "../../Components/Header/Header";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import Footer from "../../Components/Footer/Footer";
import GalleryComponent from "../../Components/GalleryComponent/GalleryComponent";
import TesteFeel from "../../Components/TasteFeel_banner/TesteFeel";
import { Form } from "react-bootstrap";
import BrandComponent from "../../Components/BrandComponent/BrandComponent";
import Mailbox from "../../Components/Footer/Mailbox";
import useDocumentTitle from "../../PageTitle";

const Gallery = () => {
  useDocumentTitle("Restaurant | Gallery")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <GalleryComponent />
      <TesteFeel />
      <Form />
      <BrandComponent />
      <Mailbox />
      <Footer />
    </div>
  );
};

export default Gallery;
