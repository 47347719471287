import React from "react";

const Highlight2 = () => {
  return (
    <section className="troo_da_why_people_choose_wrapper about_us_why_choose our_menu">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="why_people_choose_us_title_outer  our_menu_box text-center">
              <div className="choose_us_title">
                <h2>
                  Fresh, Delicious meal to reach your optimum health and fitness
                </h2>
                <p>
                  <span>15% instant discount</span> up to $150 on table booking
                  and online order
                </p>
              </div>
              <div className="our_menu_choose_btn">
                <button type="button" className="btn btn-primary">
                  Reservation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Highlight2;
