import resorent6 from "../../images/restorant_img_6.png";
import resorent7 from "../../images/restorant_img_7.png";
import resorent8 from "../../images/restorant_img_8.png";
import resorent9 from "../../images/restorant_img_9.png";

export const GalleryData = [
    {
        id :1,
        img : resorent6,
        ved : "https://www.youtube.com/embed/NUq6NlJ1oyo"
    },
    {
        id :2,
        img : resorent7,
        ved : "https://www.youtube.com/embed/nCG8JeNhOGg"
    },
    {
        id :1,
        img : resorent8,
        ved : "https://www.youtube.com/embed/kRCH8kD1GD0"
    },
    {
        id :1,
        img : resorent9,
        ved : "https://www.youtube.com/embed/xPPLbEFbCAo"
    },
]