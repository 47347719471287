import React from "react";
import { Accordion } from "react-bootstrap";

const FAQComponent2 = () => {
  return (
    <section className="our_faq_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="client_say_section_wrapper text-center">
              <div className="hero_small_detail we_service_small_title m-auto">
                <p>Have any qusetions in mind</p>
              </div>
              <div className="service_title">
                <h2>Find your answer</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="our_faq_left">
              <div className="our_awesome_team_title">
                <h2>Questions about table booking</h2>
              </div>
              <div className="faq_content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  eiusmod tempor incididunt ut labore et dolore magna Eaque
                </p>
              </div>
           
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h5>How do I Claim a Free Coupon?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h5>How do I Make a regular Table Booking?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h5>How can I be certain my booking's been received?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h5>What happens if I'm running late?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <h5>Why do you need my email address?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="our_faq_right">
              <div className="our_awesome_team_title">
                <h2>Questions about table booking</h2>
              </div>
              <div className="faq_content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  eiusmod tempor incididunt ut labore et dolore magna Eaque
                </p>
              </div>
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h5>How do I Claim a Free Coupon?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h5>How do I Make a regular Table Booking?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h5>How can I be certain my booking's been received?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h5>What happens if I'm running late?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <h5>Why do you need my email address?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="our_faq_left">
              <div className="our_awesome_team_title">
                <h2>Questions about table booking</h2>
              </div>
              <div className="faq_content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  eiusmod tempor incididunt ut labore et dolore magna Eaque
                </p>
              </div>
              <Accordion defaultActiveKey="2">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h5>How do I Claim a Free Coupon?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h5>How do I Make a regular Table Booking?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h5>How can I be certain my booking's been received?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h5>What happens if I'm running late?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <h5>Why do you need my email address?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="our_faq_right">
              <div className="our_awesome_team_title">
                <h2>Questions about table booking</h2>
              </div>
              <div className="faq_content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  eiusmod tempor incididunt ut labore et dolore magna Eaque
                </p>
              </div>
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h5>How do I Claim a Free Coupon?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h5>How do I Make a regular Table Booking?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h5>How can I be certain my booking's been received?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h5>What happens if I'm running late?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <h5>Why do you need my email address?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQComponent2;
