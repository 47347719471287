import React from "react";
import "./CommingCoonPage.css";
import comming from "../../images/coming_soon_img.png";

const CommingCoonPage = () => {
  return (
    <section className="coming_soon_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="coming_soon_left_part">
              <div className="coming_soon_title">
                <h2>We are coming soon</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing typesetting
                  industry.
                </p>
              </div>
              <div className="coming_soon_mail_form">
                <div className="coming_soon_mail_form_title">
                  <h5>Get notified when we launch</h5>
                </div>
                <form className="d-flex">
                  <div className="form_outer_sign_up">
                    <input
                      type="email"
                      className="form-control sign_up"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Your Email"
                    />
                  </div>
                  <div className="mail_box_submit coming_soon_submit">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
                <div className="coming_Soon_btn">
                  <button type="button" className="btn btn-primary">
                    Go to Homepage
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="coming_soon_img">
              <img src={comming} alt="coming_soon_img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommingCoonPage;
