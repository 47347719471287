import React from "react";
import "./AboutComponent.css";
import about from "../../images/service_img.png";
import discount from "../../images/discount.svg";
import location from "../../images/location.svg";

const AboutComponent = () => {
  return (
    <section className="troo_da_we_service_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="troo_da_we_service_left_img">
              <img src={about} alt="service_img" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="troo_da_we_service_right_detail">
              <div className="hero_small_detail we_service_small_title">
                <p>We serve since 2000</p>
              </div>
              <div className="service_title">
                <h2>About our restaurant</h2>
              </div>
              <div className="service_content">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled
                </p>
              </div>
              <div className="service_some_detail_outer d-flex">
                <div className="discount_outer">
                  <div className="discount_outer_inner">
                    <img src={discount} alt="discount" />
                  </div>
                </div>
                <div className="discount_detail_outer">
                  <h5>We give best discount</h5>
                  <p>
                    Lorem Ipsum is simply dummy text the printing & typesetting
                    industry. Lorem Ipsum has been.
                  </p>
                </div>
              </div>
              <div className="service_some_detail_outer d-flex">
                <div className="discount_outer">
                  <div className="discount_outer_inner">
                    <img src={location} alt="location" />
                  </div>
                </div>
                <div className="discount_detail_outer">
                  <h5>08+ restaurant location</h5>
                  <p>
                    Lorem Ipsum is simply dummy text the printing & typesetting
                    industry. Lorem Ipsum has been.
                  </p>
                </div>
              </div>
              <div className="service_btn">
                <button type="button" className="btn btn-primary">
                  Know More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutComponent;
