import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import chef1 from "../../images/our_chef_1.png";
import chef2 from "../../images/our_chef_2.png";
import chef3 from "../../images/our_chef_3.png";

export const TeamData = [
  {
    id: 1,
    name: "Henry Nichols",
    desg: "Chief Instructor",
    img: chef1,
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
    ],
  },
  {
    id: 2,
    name: "Emma Newman",
    desg: "CEO",
    img: chef2,
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
    ],
  },
  {
    id: 3,
    name: "Dominic Jorden",
    desg: "COOK",
    img: chef3,
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
    ],
  },
  {
    id: 4,
    name: "Henry Nichols",
    desg: "Chief Instructor",
    img: chef1,
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
    ],
  },
  {
    id: 5,
    name: "Emma Newman",
    desg: "CEO",
    img: chef2,
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
    ],
  },
  {
    id: 6,
    name: "Dominic Jorden",
    desg: "COOK",
    img: chef3,
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
    ],
  },
];
