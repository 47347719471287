import React from "react";

const Mailbox = () => {
  return (
    <section className="mail_box_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="mail_box_left">
              <h5>
                Subscribe to our newsletter to stay in touch with the latest
                updates and offers
              </h5>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mail-box-right">
              <form className="d-flex">
                <div className="form_outer_sign_up">
                  <input
                    type="email"
                    className="form-control sign_up"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="example@email.com"
                  />
                </div>
                <div className="mail_box_submit">
                  <button type="submit" className="btn btn-primary">
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mailbox;
