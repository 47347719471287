import React from 'react'
import "./TesteFeel.css"

const TesteFeel = () => {
  return (
    <section className="troo_da_teste_feel_wrapper">
        <div className="book_table_overlay"></div>
        <div className="container">
            <div className="row">
                <div className="test_feel_title_outer">
                    <div className="hero_small_detail we_service_small_title">
                        <p>Taste and feel the Spirit</p>
                    </div>
                    <div className="service_title">
                        <h2>Book your <span> table now</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default TesteFeel