import React from "react";
import "./FAQComponent.css";
import faq from "../../images/faq_img.png";
import Accordion from "react-bootstrap/Accordion";

const FAQComponent = () => {
  return (
    <section className="faq_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="faq_left_img">
              <img src={faq} alt="faq_img" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq_right_detail">
              <div className="hero_small_detail we_service_small_title">
                <p>Have you question in mind?</p>
              </div>
              <div className="our_awesome_team_title">
                <h2>Frequently asked questions</h2>
              </div>
              <div className="faq_content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  eiusmod tempor incididunt ut labore et dolore magna Eaque ipsa
                  quae ab illo inventor.
                </p>
              </div>

              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h5>How do I Claim a Free Coupon?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h5>How do I Make a regular Table Booking?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h5>How can I be certain my booking's been received?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h5>What happens if I'm running late?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <h5>Why do you need my email address?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy abd text ever since. dummy text of the
                      printing and typesetting
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQComponent;
