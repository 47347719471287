import React from "react";
import "./BlogDetails.css"
import blogDetails from "../../images/Blog_detail.png"
import search from "../../images/search.png"
import post1 from "../../images/post-1.png"
import post2 from "../../images/post-2.png"
import post3 from "../../images/post-3.png"
import post4 from "../../images/post-4.png"
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";

const BlogDetailsCompo = () => {
  return (
    <section className="blog_inner_detail_wrapper">
      <div className="container">
        <div className="row blog">
          <div className="col-lg-7">
            <div className="blog_detail_left_outer">
              <img src={blogDetails} alt="blog_detail" />
              <div className="blog_date_outer d-flex">
                <div className="date">
                  <p>Date: 27 August, 2022</p>
                </div>
                <div className="admin">
                  <p>By: Admin</p>
                </div>
                <div className="categories_recipes">
                  <p>Category: Recipes</p>
                </div>
              </div>
              <div className="blog_inner_cls">
                <h4>Beet and burrata salad with fried</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and dep it is
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard & dummy text ever now since the 1500s, when an
                  unknown printer took a galley of type and scrambled it to & i
                  am make a type specimen and i book. It has survived not only
                  five centuries, but also the leap into electronic &
                  typesetting, remaining essentially unchanged. It was
                  popularised in the and 1960s with the & release of Letraset
                  sheets containing Lorem Ipsum passages, & more recently with
                  desktop publishing software.{" "}
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since.
                </p>
                <h5>Lorem ipsume doller sit</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and it
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.
                </p>
                <ul>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and it
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard.
                  </li>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and it
                    typesetting industry.{" "}
                  </li>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and it
                    typesetting industry. Lorem Ipsum is simply dummy.
                  </li>
                  <li>Lorem Ipsum is simply dummy text of the printing.</li>
                </ul>
              </div>
              <div className="blog_social_icon d-flex">
                <span>Share on:</span>
                <ul className="d-flex">
                  <li>
                    <FaFacebookF/>
                  </li>
                  <li>
                    <FaInstagram/>
                  </li>
                  <li>
                    <FaLinkedinIn/>
                  </li>
                  <li>
                    <FaTwitter/>
                  </li>
                </ul>
              </div>
              <div className="comnts">
                <h4>0 Comments</h4>
                <p>Submit a Comment</p>
              </div>
              <div className="blog_dettail_form">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="blog_detail_form_inner">
                      <div className="form_box2">
                        <form>
                          <div className="row">
                            <div className="col-lg-12">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleFormControltext"
                                placeholder="Full Name"
                              />
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Email Edress"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput2"
                                  placeholder="Website"
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group txtara">
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  placeholder="Write a message....."
                                ></textarea>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form_submit_btn blog_detail">
                                <button type="submit" className="btn btn-primary">
                                  Submit
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-12 form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label"
                                for="exampleCheck1"
                              >
                                {" "}
                                save my name, email, and website in this browser
                                for the next time i comment.
                              </label>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="blog_right_detail">
              <form className="d-flex">
                <div className="form_outer_sign_up">
                  <input
                    type="search"
                    className="form-control sign_up"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Search"
                  />
                </div>
                <div className="mail_box_submit">
                  <button type="submit" className="btn btn-primary">
                    <img src={search} alt="" />
                  </button>
                </div>
              </form>
              <div className="post-title">
                <h5>Popular Post</h5>
              </div>
              <div className="post_outer d-flex">
                <div className="post_img">
                  <img src={post1} alt="post-1" />
                </div>
                <div className="post_content">
                  <div className="post_date">
                    <p>27 August, 2022</p>
                  </div>
                  <div className="post_content_title">
                    <h5>The most testy cake we’ve ever mae for you.</h5>
                  </div>
                </div>
              </div>
              <div className="post_outer d-flex">
                <div className="post_img">
                  <img src={post2} alt="post-2" />
                </div>
                <div className="post_content">
                  <div className="post_date">
                    <p>27 August, 2022</p>
                  </div>
                  <div className="post_content_title">
                    <h5>The most testy cake we’ve ever mae for you.</h5>
                  </div>
                </div>
              </div>
              <div className="post_outer d-flex">
                <div className="post_img">
                  <img src={post3} alt="post-3" />
                </div>
                <div className="post_content">
                  <div className="post_date">
                    <p>27 August, 2022</p>
                  </div>
                  <div className="post_content_title">
                    <h5>The most testy cake we’ve ever mae for you.</h5>
                  </div>
                </div>
              </div>
              <div className="post_outer d-flex">
                <div className="post_img">
                  <img src={post4} alt="post-4" />
                </div>
                <div className="post_content">
                  <div className="post_date">
                    <p>27 August, 2022</p>
                  </div>
                  <div className="post_content_title">
                    <h5>The most testy cake we’ve ever mae for you.</h5>
                  </div>
                </div>
              </div>
              <div className="blog_populer_cate_outer">
                <div className="blog_populer_box">
                  <div className="blog_populer_cate_outer_title">
                    <h5>Popular Categories</h5>
                  </div>
                  <div className="blog_populer_cate_outer_list_outer d-flex justify-content-between">
                    <div className="blog_popyuler_cate_name">
                      <p>Food</p>
                    </div>
                    <div className="blog_popyuler_cate_price">
                      <p>05</p>
                    </div>
                  </div>
                  <div className="blog_populer_cate_outer_list_outer d-flex justify-content-between">
                    <div className="blog_popyuler_cate_name">
                      <p>Sreet Food</p>
                    </div>
                    <div className="blog_popyuler_cate_price">
                      <p>02</p>
                    </div>
                  </div>
                  <div className="blog_populer_cate_outer_list_outer d-flex justify-content-between">
                    <div className="blog_popyuler_cate_name">
                      <p>Sea Food</p>
                    </div>
                    <div className="blog_popyuler_cate_price">
                      <p>01</p>
                    </div>
                  </div>
                  <div className="blog_populer_cate_outer_list_outer d-flex justify-content-between">
                    <div className="blog_popyuler_cate_name">
                      <p>Recipes</p>
                    </div>
                    <div className="blog_popyuler_cate_price">
                      <p>03</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post_blog_discount_img">
                <div className="post_blog_img_contnt">
                  <h4>
                    <span>20% Discount</span> on Online Table Bokking{" "}
                  </h4>
                  <div className="book_now_btn">
                    <button className="book_now_btn">Book Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsCompo;
