import React from "react";
import menuDetail from "../../images/menu_dish_detail_img.png";
import verify from "../../images/verify_btn.png";
import "./MenuItemdeatils.css";
import { MenuItemData } from "./MenuItemData";
import { Link, createSearchParams } from "react-router-dom";

const MenuItemDetails = () => {
  return (
    <>
      <section className="Menu_dish_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="menu_dish_detail2_left">
                <div className="menu_dish_detail2_img">
                  <img src={menuDetail} alt="menu_dish_detail_img" />
                </div>
                <div className="menu_dish_detail2_content">
                  <p>
                    Lorem Ipsum is simply dummy text and of the printing and
                    typesetting industry. Lorem Ipsum has been the industry’s
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into end it electronic typesetting,
                    remaining essentially unchanged. It was popularised in the
                    1960s with the release of Letraset sheets containing Lorem
                    Ipsum passages, and more recently with desktop publishing
                    software is this like Aldus PageMaker including versions of
                    Lorem Ipsum.
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text and of the printing and
                    typesetting industry. Lorem Ipsum has been the industry’s
                    standard dummy text ever since the 1500.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="menu_dish_detail2_right">
                <div className="menu_dish_detail2_title">
                  <h4>Bean Soup</h4>
                </div>
                <div className="menu_dish_detail2_price">
                  <h5>$26.00</h5>
                </div>
                <div className="menu_dish_detail2_verify_outer d-flex">
                  <div className="veryfy_img">
                    <img src={verify} alt="verify_btn" />
                  </div>
                  <div className="verify_content">
                    <p>Available In Stock</p>
                  </div>
                </div>
                <div className="menu_dish_detail2_right_content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse at felis laoreet massa cursus pulvinar. Donec
                    non and it eleifend augue, id tristique nisi. Nunc in leo
                    augue. Cras sapien quam, dictum et molestie id, ultricies
                    Lorem ipsum dolor and sit amet, consectetur adipiscing elit.
                    Suspendisse{" "}
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse at felis laoreet massa cursus pulvinar. Donec
                    non and it eleifend augue, id tristique nisi.{" "}
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="menu_dish_detail2_food_box">
                      <div className="food_box_title">
                        <h4>Ingredients</h4>
                      </div>
                      <div className="food_box_list">
                        <ul>
                          <li>60 ml extra virgin olive</li>
                          <li>Ghee 15gm</li>
                          <li>Water: 250ml</li>
                          <li>Potato: 250gm</li>
                          <li>Mustard Oil: 15ml</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 nutri_box_2">
                    <div className="menu_dish_detail2_food_box">
                      <div className="food_box_title">
                        <h4>Nutrition</h4>
                      </div>
                      <div className="food_box_list">
                        <ul>
                          <li>Protine: 6.60g</li>
                          <li>Fat: 10g</li>
                          <li>Minerel: 15g</li>
                          <li>Water: 150ml</li>
                          <li>Carbohydrate : 15g</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="troo_da_most_populer_wrapper our_menu_most_populer our_menu_2 our_menu_dish_detail">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="most_popular_dishes_title text-left">
                <div className="service_title">
                  <h2>Other Dishes</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {MenuItemData.slice(0, 4).map((e, i) => (
              <div className="col-lg-3" key={i}>
                <Link
                  to={`/our-menu2/menu-details?${createSearchParams({
                    id: e.id,
                  })}`}
                >
                  <div className="dish_box">
                    <div className="dish-img">
                      <img src={e.img} alt="dish_1" />
                    </div>
                    <div className="dish_dettail_outer">
                      <div className="dish_title">
                        <h5>{e.name}</h5>
                        <p>{e.commt}</p>
                      </div>
                      <div className="dish_price_tag">
                        <p>
                          <span>Price:</span> {e.price}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MenuItemDetails;
