import React, { useEffect } from "react";
import "./Header.css";
import logo from "../../images/Header_Logo.svg";
import { Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { HeaderData } from "./HeaderData";
import NavMenu from "./NavMenu";

const Header = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <header>
      <div className="container">
        <Navbar expand="lg">
          <Navbar.Brand>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <ul className="navbar-nav ">
              {HeaderData.slice(0, 6).map((item, i) => {
                return <NavMenu key={i} item={item} />;
              })}
            </ul>

            <div className="header_btn">
              {HeaderData.slice(-1).map((e, i) => {
                return (
                  <button
                    type="button"
                    className="btn btn-primary"
                    key={i}
                    onClick={() => navigate(e.path)}
                  >
                    {e.title}
                  </button>
                );
              })}
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
