import React from "react";
import Header from "../Components/Header/Header";
import BannerGlobal from "../Components/Banner/BannerGlobal";
import Footer from "../Components/Footer/Footer";
import Form3 from "../Components/Form/Form3";
import Highlight2 from "../Components/Highlights/Highlight2";
import Mailbox from "../Components/Footer/Mailbox";
import BrandComponent from "../Components/BrandComponent/BrandComponent";
import useDocumentTitle from "../PageTitle";

const Reservation = () => {
  useDocumentTitle("Restaurant | Reservation")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <Form3 />
      <Highlight2 />
      <BrandComponent />
      <Mailbox />
      <Footer />
    </div>
  );
};

export default Reservation;
