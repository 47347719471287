import React from "react";
import Header from "../Components/Header/Header";
import BannerGlobal from "../Components/Banner/BannerGlobal";
import Footer from "../Components/Footer/Footer";
import MapComponent from "../Components/ContactComponent/MapComponent";
import Form2 from "../Components/Form/Form2";
import BrandComponent from "../Components/BrandComponent/BrandComponent";
import Mailbox from "../Components/Footer/Mailbox";
import useDocumentTitle from "../PageTitle";

const Contact = () => {
  useDocumentTitle("Restaurant | Contacts")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <MapComponent />
      <Form2 />
      <BrandComponent />
      <Mailbox />
      <Footer />
    </div>
  );
};

export default Contact;
