import React from "react";
import Header from "../../Components/Header/Header";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import Footer from "../../Components/Footer/Footer";
import BlogDetailsCompo from "../../Components/BlogComponent/BlogDetailsCompo";
import BrandComponent from "../../Components/BrandComponent/BrandComponent";
import Mailbox from "../../Components/Footer/Mailbox";
import useDocumentTitle from "../../PageTitle";

const BlogDetails = () => {
  useDocumentTitle("Restaurant | Blog Details")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <BlogDetailsCompo />
      <BrandComponent />
      <Mailbox />
      <Footer />
    </div>
  );
};

export default BlogDetails;
