import React from "react";
import "./MapComponent.css"
import call from "../../images/map_cll.png";
import mail from "../../images/maill_call.png";
import location from "../../images/location_map.png"
import { Link } from "react-router-dom";

const MapComponent = () => {
  return (
    <section className="map_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="client_say_section_wrapper text-center">
              <div className="hero_small_detail we_service_small_title m-auto">
                <p>Feel free to contact with us!</p>
              </div>
              <div className="service_title">
                <h2>We’re ready to help you</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="map_outer">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.8850349918985!2d72.49642611542339!3d23.027993021903114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1674649284683!5m2!1sen!2sin"
                width="600"
                height="450"
                title="Gmap"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="map_right_box">
              <div className="map_title">
                <div className="hero_small_detail we_service_small_title">
                  <p>Let’s Talk With Us</p>
                </div>
              </div>
              <div className="map_private_detail">
                <div className="troo-da-connect-together-form-detail-call-box d-flex">
                  <div className="call-img">
                    <img
                      src={location}
                      alt="location"
                      title="location"
                    />
                  </div>
                  <div className="call-detail ">
                    <div className="call-detail-span">
                      <Link to="">
                        <address>
                          4516 School Street <br />
                          Danbury, CT, Canada
                        </address>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="troo-da-connect-together-form-detail-call-box d-flex">
                  <div className="call-img">
                    <img src={call} alt="call" title="call" />
                  </div>
                  <div className="call-detail ">
                    <div className="call-detail-span">
                      <a href="tel:+44 123 456 7890">+44 123 456 7890</a>
                      <a href="tel:+44 7890 456 123">+44 7890 456 123</a>
                    </div>
                  </div>
                </div>
                <div className="troo-da-connect-together-form-detail-call-box d-flex">
                  <div className="call-img">
                    <img src={mail} alt="mail" title="mail" />
                  </div>
                  <div className="call-detail ">
                    <div className="call-detail-span">
                      <a href="mailto:example@email.com">example@email.com</a>
                      <a href="mailto:Dummy@email.com">Dummy@email.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MapComponent;
