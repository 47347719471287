import React from "react";
import "./ClientReview.css";
import { ClientData } from "./ClientData";

const ClientReview = () => {
  return (
    <section className="troo_da_client_say_Wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="client_say_section_wrapper text-center">
              <div className="hero_small_detail we_service_small_title m-auto">
                <p>A lot of happy clients</p>
              </div>
              <div className="service_title">
                <h2>What our clients say about us</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {ClientData.map((e, i) => (
            <div className="col-lg-4">
              <div className="client_say_box">
                <div className="client_say_box_title">
                  <h4>{e.comment} </h4>
                </div>
                <div className="client_say_box_content">
                  <p>{e.para} </p>
                </div>
                <div className="client_box_star">
                  {e.icon}
                  {e.icon}
                  {e.icon}
                  {e.icon}
                  {e.icon}
                </div>
                <div className="client_box_client_name">
                  <h5>{e.name} </h5>
                  <p>{e.desg} </p>
                </div>
                <div className="client_box_person_img">
                  <img src={e.img} alt="clients_review_img_1" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="client_say_btn text-center">
          <button type="button" className="btn btn-primary">
            Read All Review
          </button>
        </div>
      </div>
    </section>
  );
};

export default ClientReview;
