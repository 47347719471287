import React from "react";
import "./Footer.css";
import { FaTwitter, FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../images/Footer_Logo.png"


const Footer = () => {
  const theme = "https://troothemes.com/"
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="footer_box">
              <div className="footer_title">
                <h3>Opening hours</h3>
              </div>
              <div className="footer_content">
                <p>Monday to Friday</p>
              </div>
              <div className="footer_time">
                <p>11:00 AM to 22:00 PM</p>
              </div>
              <div className="footer_content content-2">
                <p>Saturday and Sunday</p>
              </div>
              <div className="footer_time">
                <p>11:00 AM to 23:59 PM</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer_box second">
              <div className="footer_title">
                <h3>Call us</h3>
              </div>
              <div className="footer_call_1">
                <a href="tel:+44 123 456 7890">+44 123 456 7890</a>
              </div>
              <div className="footer_call_2">
                <a href="tel:+44 987 654 0123">+44 987 654 0123</a>
              </div>
              <div className="footer_logo">
                <img src={logo} alt="footer_logo" />
              </div>
              <div className="footer_social_icon d-flex justify-content-center">
                <span>We are on </span>
                <ul className="d-flex">
                  <li>
                    <FaFacebookF/>
                  </li>
                  <li>
                   <FaTwitter/>
                  </li>
                  <li>
                    <FaLinkedinIn/>
                  </li>
                  <li>
                    <FaInstagram/>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer_box">
              <div className="footer_title">
                <h3>Write a message</h3>
              </div>
              <div className="footer_user_mail">
                <a href="mailto:example@email.com">example@email.com</a>
              </div>
              <div className="footer_title">
                <h3>Our address</h3>
              </div>
              <div className="footer_user_addres">
                <address>
                  32 Park Row, Edinburgh United Kingdom, EH2 1TT
                </address>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="footer_bottom_menu">
              <div className="footer_menu_bottom_list_outer">
                <ul className="footer_menu_bottom_list_inner d-flex justify-content-center align-items-center">
                  <li className="footer_menu_bottom_list_inner_item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="footer_menu_bottom_list_inner_item">
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li className="footer_menu_bottom_list_inner_item">
                    <Link to="/our-menu2">Our Menu</Link>
                  </li>
                  <li className="footer_menu_bottom_list_inner_item">
                    <Link to="/faq">FAQs</Link>
                  </li>
                  <li className="footer_menu_bottom_list_inner_item">
                    <Link to="/blogs">Blogs</Link>
                  </li>
                  <li className="footer_menu_bottom_list_inner_item">
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="footer_left_part">
              <p>
                © 2022 Restaurant Theme by <Link to={theme}>Troo Theme.</Link> All rights
                reserved
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="footer_right_part">
              <p>Terms & Conditions</p>
              <span>|</span>
              <p> Privacy Policy</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
