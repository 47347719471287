import React from "react";
import Header from "../../Components/Header/Header";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import ClientReview from "../../Components/ClientReview/ClientReview";
import TeamComponent from "../../Components/TeamComponent/TeamComponent";
import Mailbox from "../../Components/Footer/Mailbox";
import Footer from "../../Components/Footer/Footer";
import MenuItem from "../../Components/MenuItem/MenuItem";
import BrandComponent from "../../Components/BrandComponent/BrandComponent";
import Highlights from "../../Components/Highlights/Highlights";
import PopularItem from "../../Components/MenuItem/PopularItem";
import Highlight2 from "../../Components/Highlights/Highlight2";
import MainItemDesert from "../../Components/MenuItem/MainItemDesert";
import MainItemDrink from "../../Components/MenuItem/MainItemDrink";

const Menu2 = () => {
  return (
    <div className="menu2">
      <Header />
      <BannerGlobal />
      <MenuItem />
      <Highlights/>
      <PopularItem/>
      <Highlight2/>
      <MainItemDesert/>
      <Highlight2/>
      <MainItemDrink/>
      <ClientReview />
      <TeamComponent />
      <BrandComponent/>
      <Mailbox />
      <Footer />
    </div>
  );
};

export default Menu2;
