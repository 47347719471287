import React from "react";
import { TeamData } from "./TemData";

const TeamListComponent = () => {
  return (
    <section className="our_chefe_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="most_popular_dishes_title text-center">
              <div className="hero_small_detail we_service_small_title m-auto">
                <p>They are ready to treat you</p>
              </div>
              <div className="service_title">
                <h2>Our experienced chefs</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {TeamData.map((e, i) => (
            <div className="col-lg-4">
              <div className="our_chef_box">
                <div className="our_chef_img">
                  <img src={e.img} alt="our_chef_1" />
                </div>
                <div className="or_chef_box_bottom">
                  <div className="our_chef_title">
                    <h4>{e.name}</h4>
                    <p>{e.desg}</p>
                  </div>
                  <div className="our_chef_social_icon">
                    <ul className="d-flex">
                      {e.social.map((e, i) => (
                        <li key={i}>{e.icon}</li>
                      ))}
                
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamListComponent;
