import React from "react";
import Header from "../../Components/Header/Header";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import Footer from "../../Components/Footer/Footer";
import FAQComponent2 from "../../Components/FAQComponent/FAQComponent2";
import TesteFeel from "../../Components/TasteFeel_banner/TesteFeel";
import Form from "../../Components/Form/Form";
import BrandComponent from "../../Components/BrandComponent/BrandComponent";
import Mailbox from "../../Components/Footer/Mailbox";
import useDocumentTitle from "../../PageTitle";

const FAQs = () => {
  useDocumentTitle("Restaurant | FAQs")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <FAQComponent2 />
      <TesteFeel />
      <Form />
      <BrandComponent/>
      <Mailbox/>
      <Footer />
    </div>
  );
};

export default FAQs;
