import React from "react";
import "./Form.css";
import formbtm from "../../images/form_bottom.png";

const Form = () => {
  return (
    <section className="form_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="form_detail">
              <div className="form_title">
                <h4>Opening hours</h4>
              </div>
              <div className="form_content_1">
                <p>Monday to Friday: 11:00 AM to 22:00 PM</p>
              </div>
              <div className="form_content_2">
                <p>Saturday and Sunday: 11:00 AM to 11:59 PM</p>
              </div>
              <div className="form_note">
                <p>
                  <span>Note*</span> Free delivery within 10km only
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="form_box">
              <div className="form_box_title text-center m-auto">
                <h4>Make a reserve</h4>
              </div>
              <form>
                <div className="row">
                  <div className="col-lg-6">
                    <label for="exampleFormControltext">Your Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControltext"
                      placeholder="John Doe"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label for="exampleFormControlnumber">Phone No</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlnumber"
                      placeholder="+44 123 456 7890"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="exampleFormControlInput1">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="example@email.com"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="exampleFormControlSelect1">Persons</label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="exampleFormControldate">Date</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleFormControldate"
                        placeholder="example@email.com"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="exampleFormControlSelect2">Time </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect2"
                      >
                        <option>12:00 PM</option>
                        <option>12:00 PM</option>
                        <option>12:00 PM</option>
                        <option>12:00 PM</option>
                        <option>12:00 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form_submit_btn">
                      <button type="submit" className="btn btn-primary">
                        Book a Table
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="form_bottom_layer">
                <img src={formbtm} alt="form_bottom" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form;
