import review1 from "../../images/clients_review_img_1.png";
import review2 from "../../images/clients_review_img_2.png";
import review3 from "../../images/clients_review_img_3.png";
import { FaStar } from "react-icons/fa";

export const ClientData = [
  {
    id: 0,
    img: review1,
    comment: "“Superb service”",
    para: "Lorem ipsum dolor sit amet, consectetur Lorem & Lorem ipsum dolor sit amet, consectetur acing.Lorem ipsum dolor sit amet, & itself consectetur Lorem and Lorem ipsum dolor sitamet.",
    name: "Andrew thomas",
    desg: "MBA Student ",
    icon: <FaStar />,
  },
  {
    id: 1,
    img: review2,
    comment: "“Awesome food test”",
    para: "Lorem ipsum dolor sit amet, consectetur Lorem & Lorem ipsum dolor sit amet, consectetur acing.Lorem ipsum dolor sit amet, & itself consectetur Lorem and Lorem ipsum dolor sitamet.",
    name: "Alexa brethix",
    desg: "Bank Manager",
    icon: <FaStar />,
  },
  {
    id: 2,
    img: review3,
    comment: "“Very fast delivery”",
    para: "Lorem ipsum dolor sit amet, consectetur Lorem & Lorem ipsum dolor sit amet, consectetur acing.Lorem ipsum dolor sit amet, & itself consectetur Lorem and Lorem ipsum dolor sitamet.",
    name: "Jonathan carlos",
    desg: "Fitness trainer ",
    icon: <FaStar />,
  },
];
