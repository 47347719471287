import React from 'react'
import Header from '../../Components/Header/Header'
import BannerGlobal from '../../Components/Banner/BannerGlobal'
import Footer from '../../Components/Footer/Footer'
import TeamListComponent from '../../Components/TeamComponent/TeamListComponent'
import ClientReview from '../../Components/ClientReview/ClientReview'
import BrandComponent from '../../Components/BrandComponent/BrandComponent'
import Mailbox from '../../Components/Footer/Mailbox'
import useDocumentTitle from '../../PageTitle'

const Team = () => {
  useDocumentTitle("Restaurant | Teams")
  return (
    <div className='team'>
        <Header/>
        <BannerGlobal/>
        <TeamListComponent/>
        <ClientReview/>
        <BrandComponent/>
      <Mailbox />
        <Footer/>
    </div>
  )
}

export default Team