import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Routes/Home";
import About from "./Routes/About";
import Menu from "./Routes/Menu/Menu";
import Team from "./Routes/Pages/Team";
import Testimonial from "./Routes/Pages/Testimonial";
import FAQs from "./Routes/Pages/FAQs";
import Gallery from "./Routes/Pages/Gallery";
import Error404 from "./Routes/Pages/Error404";
import CommingSoon from "./Routes/Pages/CommingSoon";
import Blogs from "./Routes/Blogs/Blogs";
import BlogDetails from "./Routes/Blogs/BlogDetails";
import Contact from "./Routes/Contact";
import Reservation from "./Routes/Reservation";
import Menu2 from "./Routes/Menu/Menu2";
import MenuDetails from "./Routes/Menu/MenuDetails";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/our-menu" element={<Menu />} />
          <Route path="/our-menu2" element={<Menu2/>} />
          <Route path="/our-menu2/menu-details" element={<MenuDetails />} />
          <Route path="/our-team" element={<Team />} />
          <Route path="/testimonail" element={<Testimonial />} />
          <Route path="/faq" element={<FAQs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/error-404" element={<Error404 />} />
          <Route path="/comming-soon" element={<CommingSoon />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/blog-details" element={<BlogDetails />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/reservation" element={<Reservation />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
