import disk1 from "../../images/dish_1.png";
import disk2 from "../../images/dish_2.png";
import disk3 from "../../images/dish_3.png";
import disk4 from "../../images/dish_4.png";
import disk5 from "../../images/dish_5.png";
import disk6 from "../../images/dish_6.png";
import disk7 from "../../images/dish_7.png";
import disk8 from "../../images/dish_8.png";

export const MenuItemData = [
    {
        id : 1,
        name : "Gene kelly’s beef stew",
        img : disk1,
        commt : "Lorem ipsum dolor sit amet, consectetur Lorem ipsum ",
        price : "$25.00"
    },
    {
        id : 2,
        name : "Purple Corn Tostada",
        img : disk2,
        commt : "Lorem ipsum dolor sit amet, consectetur Lorem ipsum ",
        price : "$18.00"
    },
    {
        id : 3,
        name : "alt & Pepper Calamari",
        img : disk3,
        commt : "Lorem ipsum dolor sit amet, consectetur Lorem ipsum ",
        price : "$30.00"
    },
    {
        id : 4,
        name : "Wild Mushroom Arancini",
        img : disk4,
        commt : "Lorem ipsum dolor sit amet, consectetur Lorem ipsum ",
        price : "$25.00"
    },
    {
        id : 5,
        name : "Fresh Oysters Dozen",
        img : disk5,
        commt : "Lorem ipsum dolor sit amet, consectetur Lorem ipsum ",
        price : "$55.00"
    },
    {
        id : 6,
        name : "Bread with Smoked Wagyu",
        img : disk6,
        commt : "Lorem ipsum dolor sit amet, consectetur Lorem ipsum ",
        price : "$35.00"
    },
    {
        id : 7,
        name : "Coffee Cured & Smoked",
        img : disk7,
        commt : "Lorem ipsum dolor sit amet, consectetur Lorem ipsum ",
        price : "$20.00"
    },
    {
        id : 8,
        name : "Fresh Oysters Dozen",
        img : disk8,
        commt : "Lorem ipsum dolor sit amet, consectetur Lorem ipsum ",
        price : "$80.00"
    },


]