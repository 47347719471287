import React from "react";
import "./Category.css";

const Category = () => {
  return (
    <section class="troo_da_categories_wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="categories_title_outer text-center">
              <div class="hero_small_detail we_service_small_title m-auto">
                <p>Choose your categories</p>
              </div>
              <h2>What do you like today?</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="categories_box d-flex">
              <div class="categories_number">
                <div class="categories_inner_number">
                  <span>01</span>
                </div>
              </div>
              <div class="categories_detail_outer">
                <div class="categories_title">
                  <h4>Starters</h4>
                </div>
                <div class="categories-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                    sit amet, consectetur adipisicing.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="categories_box d-flex">
              <div class="categories_number">
                <div class="categories_inner_number">
                  <span>02</span>
                </div>
              </div>
              <div class="categories_detail_outer">
                <div class="categories_title">
                  <h4>Main dishes</h4>
                </div>
                <div class="categories-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                    sit amet, consectetur adipisicing.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="categories_box d-flex">
              <div class="categories_number">
                <div class="categories_inner_number">
                  <span>03</span>
                </div>
              </div>
              <div class="categories_detail_outer">
                <div class="categories_title">
                  <h4>Drinks</h4>
                </div>
                <div class="categories-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                    sit amet, consectetur adipisicing.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="categories_box d-flex">
              <div class="categories_number">
                <div class="categories_inner_number">
                  <span>04</span>
                </div>
              </div>
              <div class="categories_detail_outer">
                <div class="categories_title">
                  <h4>Desserts</h4>
                </div>
                <div class="categories-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                    sit amet, consectetur adipisicing.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Category;
