import React from "react";
import { BlogData } from "./BlogData";
import { Link, createSearchParams } from "react-router-dom";

const BlogComponent2 = () => {
  return (
    <section className="troo_da_how_can_help_u_wrapper our_blogs_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="client_say_section_wrapper text-center">
              <div className="hero_small_detail we_service_small_title m-auto">
                <p>Latest updates</p>
              </div>
              <div className="service_title">
                <h2>Read latest blogs and articles</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {BlogData.slice(0, 3).map((e, i) => (
            <div className="col-lg-4" key={i}>
              <Link
                to={`/blogs/blog-details?${createSearchParams({
                  id: e.id,
                })}`}
              >
                <div className="troo_da_blog_box">
                  <div className="troo_da_blog_inner_box">
                    <div className="detail_outer">
                      <div className="troo_da_blog_box_date">
                        <p>{e.date}</p>
                      </div>
                      <div className="troo_da_blog_box_date">
                        <p>{e.desg} </p>
                      </div>
                    </div>
                    <div className="troo_da_blog_box_title">
                      <h4>{e.name} </h4>
                    </div>
                    <div className="troo_da_blog_box_content">
                      <p>{e.para}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="row">
          {BlogData.slice(3, 6).map((e, i) => (
            <div className="col-lg-4" key={i}>
              <Link
                to={`/blogs/blog-details?${createSearchParams({
                  id: e.id,
                })}`}
              >
                <div className="troo_da_blog_box">
                  <div className="troo_da_blog_inner_box">
                    <div className="detail_outer">
                      <div className="troo_da_blog_box_date">
                        <p>{e.date}</p>
                      </div>
                      <div className="troo_da_blog_box_date">
                        <p>{e.desg} </p>
                      </div>
                    </div>
                    <div className="troo_da_blog_box_title">
                      <h4>{e.name} </h4>
                    </div>
                    <div className="troo_da_blog_box_content">
                      <p>{e.para}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="row">
          {BlogData.slice(6, 9).map((e, i) => (
            <div className="col-lg-4" key={i}>
              <Link
                to={`/blogs/blog-details?${createSearchParams({
                  id: e.id,
                })}`}
              >
                <div className="troo_da_blog_box">
                  <div className="troo_da_blog_inner_box">
                    <div className="detail_outer">
                      <div className="troo_da_blog_box_date">
                        <p>{e.date}</p>
                      </div>
                      <div className="troo_da_blog_box_date">
                        <p>{e.desg} </p>
                      </div>
                    </div>
                    <div className="troo_da_blog_box_title">
                      <h4>{e.name} </h4>
                    </div>
                    <div className="troo_da_blog_box_content">
                      <p>{e.para}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="read_all_article_btn text-center our_blogs_btn">
          <button type="button" className="btn btn-primary">
            Read More
          </button>
        </div>
      </div>
    </section>
  );
};

export default BlogComponent2;
