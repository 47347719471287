import React from "react";
import { MenuItemData } from "./MenuItemData";
import { Link, createSearchParams } from "react-router-dom";

const MainItemDrink = () => {
  return (
    <section className="troo_da_most_populer_wrapper our_menu_most_populer our_menu_2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="most_popular_dishes_title text-center">
              <div className="hero_small_detail we_service_small_title m-auto">
                <p>Most popular dishes</p>
              </div>
              <div className="service_title">
                <h2>Main drinks</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {MenuItemData.map((e, i) => (
            <div className="col-lg-3" key={i}>
              <Link
                to={`/our-menu2/menu-details?${createSearchParams({
                  id: e.id,
                })}`}
              >
                <div className="dish_box">
                  <div className="dish-img">
                    <img src={e.img} alt="dish_1" />
                  </div>
                  <div className="dish_dettail_outer">
                    <div className="dish_title">
                      <h5>{e.name}</h5>
                      <p>{e.commt}</p>
                    </div>
                    <div className="dish_price_tag">
                      <p>
                        <span>Price:</span> {e.price}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MainItemDrink;
