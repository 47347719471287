export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about us",
    path: "/about-us",
    heading: "Get Everything About Us",
  },
  {
    title: "Our Menu",
    path: "#",
    subNav: [
      {
        subTitle: "our menu",
        heading: "Our Most Popular Disies",
        path: "/our-menu",
      },

      {
        subTitle: "our menu2",
        heading: "Our Most Popular Disies",
        path: "/our-menu2",
      },

      {
        subTitle: "menu details",
        heading: "Gene kelly’s beef stew",
        path: "/our-menu2/menu-details",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "our team",
        heading: "View Our Awesome Team",
        path: "/our-team",
      },

      {
        subTitle: "testimonail",
        heading: "Our Awesome Client’s Review",
        path: "/testimonail",
      },

      {
        subTitle: "faq",
        heading: "Common Queries",
        path: "/faq",
      },
      {
        subTitle: "gallery",
        heading: "Our Best Plans For You",
        path: "/gallery",
      },
      {
        subTitle: "error 404",
        heading: "404 Error",
        path: "/error-404",
      },
      {
        subTitle: "comming soon",
        heading: "Comming Soon",
        path: "/comming-soon",
      },
    ],
  },
  {
    title: "Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "blogs",
        heading: "News for nerds, stuff that matters",
        path: "/blogs",
      },
      {
        subTitle: "blog details",
        heading: "Supplements & Herbs / When & Why to Take Them",
        path: "/blogs/blog-details",
      },
    ],
  },
  {
    title: "contact us",
    path: "/contact-us",
    heading: "We are Happy to Help You",
  },
  {
    title: "reservation",
    heading: "Book a Table",
    path: "/reservation",
  },
];
