import React from "react";

const Form2 = () => {
  return (
    <section className="our_contct-2_form_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="client_say_section_wrapper contct-2-small-sec text-center">
              <div className="hero_small_detail we_service_small_title m-auto">
                <p>Whrite Us Message</p>
              </div>
              <div className="service_title">
                <h2>Fill the form below</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="form_box book_table_form_box">
              <form>
                <div className="row">
                  <div className="col-lg-6">
                    <label for="exampleFormControltext">Your Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControltext"
                      placeholder="John Doe"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label for="exampleFormControlnumber">Phone No</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlnumber"
                      placeholder="+44 123 456 7890"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="exampleFormControlInput1">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="example@email.com"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="exampleFormControlSelect1">Persons</label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group txtara">
                      <label for="exampleFormControlTextarea1">
                        Write Message
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        placeholder="Typing Here....."
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form_submit_btn our_book_tble_submit contct-2 text-center">
                      <button type="submit" className="btn btn-primary">
                        Send a Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form2;
