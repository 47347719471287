import React from "react";
import "./TeamComponent.css";
import experience from "../../images/experience_chef.png";

const TeamComponent = () => {
  return (
    <section className="our_awesome_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="our_awesome_team_left_detail">
              <div className="hero_small_detail we_service_small_title">
                <p>Our awesome team</p>
              </div>
              <div className="our_awesome_team_title">
                <h2>
                  Meet our professional <span>chefs</span>
                </h2>
              </div>
              <div className="our_awesome_content-1">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Eaque ipsa quae ab illo inventor.
                </p>
              </div>
              <div className="our_awesome_content_2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Eaque ipsa quae ab illo inventor.Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua.
                </p>
              </div>
              <div className="our_awesome_btn">
                <button type="button" className="btn btn-primary">
                  View All Chefs
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="our_awesome_right_img">
              <div className="our_awesome_img">
                <img src={experience} alt="experience_chef" />
              </div>
              <div className="our_awosome_img_box">
                <h2>15+</h2>
                <p>Experienced Chef</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamComponent;
