import React from "react";
import "./Highlights.css";
import thumb from "../../images/Best_food_quality.png";
import location from "../../images/08_Restaurant.png";
import pc from "../../images/Online_oreder.png";
import square from "../../images/fast_delivery.png";

const Highlights = () => {
  return (
    <section className="troo_da_why_people_choose_wrapper">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="why_people_choose_us_title_outer text-center">
              <div className="hero_small_detail m-auto">
                <p>className the new flavour</p>
              </div>
              <div className="choose_us_title">
                <h2>Doing more than expect</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="why_people_choose_box">
              <div className="why_people_choose_img m-auto">
                <img src={thumb} alt="Best_food_quality" />
              </div>
              <div className="why_people_choose_title">
                <h5>Best food quality</h5>
              </div>
              <div className="why_people_choose_content">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="why_people_choose_box">
              <div className="why_people_choose_img m-auto">
                <img src={location} alt="08 Restaurant" />
              </div>
              <div className="why_people_choose_title">
                <h5>08+ Restaurant</h5>
              </div>
              <div className="why_people_choose_content">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="why_people_choose_box">
              <div className="why_people_choose_img m-auto">
                <img src={pc} alt="Online_oreder" />
              </div>
              <div className="why_people_choose_title">
                <h5>Online oreder</h5>
              </div>
              <div className="why_people_choose_content">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="why_people_choose_box">
              <div className="why_people_choose_img m-auto">
                <img src={square} alt="fast_delivery" />
              </div>
              <div className="why_people_choose_title">
                <h5>Fast delivery</h5>
              </div>
              <div className="why_people_choose_content">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Highlights;
