import React from "react";
import Header from "../../Components/Header/Header";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import Footer from "../../Components/Footer/Footer";
import Highlights from "../../Components/Highlights/Highlights";
import MainDisies from "../../Components/MenuTable/MainDisies";
import MainStarter from "../../Components/MenuTable/MainStarter";
import Highlight2 from "../../Components/Highlights/Highlight2";
import PopularDisies from "../../Components/MenuTable/PopularDisies";
import MainDrink from "../../Components/MenuTable/MainDrink";
import ClientReview from "../../Components/ClientReview/ClientReview";
import TeamComponent from "../../Components/TeamComponent/TeamComponent";
import Mailbox from "../../Components/Footer/Mailbox";
import useDocumentTitle from "../../PageTitle";

const Menu = () => {
  useDocumentTitle("Restaurant | Our Menus")
  return (
    <div className="menu2">
      <Header />
      <BannerGlobal />
      <MainDisies />
      <Highlights />
      <MainStarter />
      <Highlight2 />
      <PopularDisies />
      <Highlight2 />
      <MainDrink />
      <ClientReview />
      <TeamComponent />
      <Mailbox />
      <Footer />
    </div>
  );
};

export default Menu;
