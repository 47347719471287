import React from "react";
import "./DownloadBanner.css";
import google from "../../images/Google-Play.png"
import store from "../../images/App-Store.png"
import mobile from "../../images/mobile_img.png"

const DownloadBanner = () => {
  return (
    <section className="download_wrapper">
      <div className="downLoad_overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="download_left_detail">
              <div className="FontH1">
                <h2>Download our mobile app.</h2>
              </div>
              <div className="download_content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  eiusmod tempor incididunt ut labore et dolore magna . Eaque
                  ipsa quae ab illo inventor.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  eiusmod tempor incididunt ut labore et dolore magna . Eaque
                  ipsa quae ab illo inventor.
                </p>
              </div>
              <div className="device_outer d-flex">
                <div className="google_play">
                  <img
                    src={google}
                    alt="Google-Play"
                  />
                </div>
                <div className="app_store">
                  <img src={store} alt="App-Store" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="download_left_img">
              <img src={mobile} alt="mobile_img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadBanner;
