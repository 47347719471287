import React from "react";
import person from "../../images/book_table_persone.png";
const Form3 = () => {
  return (
    <section className="form_wrapper book_table_form_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="form_box book_table_form_box">
              <form>
                <div className="row">
                  <div className="col-lg-6">
                    <label for="exampleFormControltext">Your Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControltext"
                      placeholder="John Doe"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label for="exampleFormControlnumber">Phone No</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlnumber"
                      placeholder="+44 123 456 7890"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="exampleFormControlInput1">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="example@email.com"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="exampleFormControlSelect1">Persons</label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="exampleFormControldate">Date</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleFormControldate"
                        placeholder="example@email.com"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="exampleFormControlSelect2">Time </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect2"
                      >
                        <option>12:00 PM</option>
                        <option>12:00 PM</option>
                        <option>12:00 PM</option>
                        <option>12:00 PM</option>
                        <option>12:00 PM</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form_submit_btn our_book_tble_submit">
                      <button type="submit" className="btn btn-primary">
                        Book a Table
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="book_table_right_side_img">
              <div className="book_table_right_side_inner_img">
                <img src={person} alt="book_table_persone" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form3;
