import React from "react";

const MainStarter = () => {
  return (
    <section className="troo_da_most_populer_wrapper our_menu_most_populer">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="most_popular_dishes_title text-center">
              <div className="hero_small_detail we_service_small_title m-auto">
                <p>Most popular dishes</p>
              </div>
              <div className="service_title">
                <h2>Main starter</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="menu_outer">
              <div className="menu_title">
                <div className="menu_box">
                  <div className="menu_dish_name_outer d-flex justify-content-between">
                    <div className="menu_dish_name">
                      <h5>Albacore tuna</h5>
                    </div>
                    <div className="menu_dish_price">
                      <h5>$05</h5>
                    </div>
                  </div>
                  <div className="menu_content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                      sit ame.
                    </p>
                  </div>
                </div>
                <div className="menu_box">
                  <div className="menu_dish_name_outer d-flex justify-content-between">
                    <div className="menu_dish_name">
                      <h5>Crispy chicken melt</h5>
                    </div>
                    <div className="menu_dish_price">
                      <h5>$15</h5>
                    </div>
                  </div>
                  <div className="menu_content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                      sit ame.
                    </p>
                  </div>
                </div>
                <div className="menu_box">
                  <div className="menu_dish_name_outer d-flex justify-content-between">
                    <div className="menu_dish_name">
                      <h5>Downtown wrap</h5>
                    </div>
                    <div className="menu_dish_price">
                      <h5>$10</h5>
                    </div>
                  </div>
                  <div className="menu_content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                      sit ame.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="menu_outer">
              <div className="menu_title">
                <div className="menu_box">
                  <div className="menu_dish_name_outer d-flex justify-content-between">
                    <div className="menu_dish_name">
                      <h5>Gene kelly’s beef stew</h5>
                    </div>
                    <div className="menu_dish_price">
                      <h5>$09</h5>
                    </div>
                  </div>
                  <div className="menu_content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                      sit ame.
                    </p>
                  </div>
                </div>
                <div className="menu_box">
                  <div className="menu_dish_name_outer d-flex justify-content-between">
                    <div className="menu_dish_name">
                      <h5>The mixwd grill</h5>
                    </div>
                    <div className="menu_dish_price">
                      <h5>$05</h5>
                    </div>
                  </div>
                  <div className="menu_content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                      sit ame.
                    </p>
                  </div>
                </div>
                <div className="menu_box">
                  <div className="menu_dish_name_outer d-flex justify-content-between">
                    <div className="menu_dish_name">
                      <h5>Kansas chicken</h5>
                    </div>
                    <div className="menu_dish_price">
                      <h5>$06</h5>
                    </div>
                  </div>
                  <div className="menu_content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor
                      sit ame.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainStarter;
