import React, { useState } from "react";
import "./GalleryComponent.css";
import resorent1 from "../../images/restorant_img_1.png";
import resorent2 from "../../images/restorant_img_2.png";
import resorent3 from "../../images/restorant_img_3.png";
import resorent4 from "../../images/restorant_img_4.png";
import resorent5 from "../../images/restorant_img_5.png";
import resorent6 from "../../images/restorant_img_6.png";
import icon from "../../images/youtube_icon.png";
import { IoCloseOutline } from "react-icons/io5";
import { GalleryData } from "./GAlleryData";

const GalleryComponent = () => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();
  const openModal = (e) => {
    if (!modal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    setModalData(e);
    setModal(!modal);
  };
  return (
    <>
      <section className="restorant_images_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="restorant_images_title_outer">
                <h4>Restaurant images</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  eiusmod tempor incididunt ut labore et dolore magna Eaque
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="restorant_images restro_common">
                <img src={resorent1} alt="restorant_img_1" />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="restorant_img_2 restro_common">
                <img src={resorent2} alt="restorant_img_2" />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="restorant_img_2 restro_common">
                <img src={resorent3} alt="restorant_img_3" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="restorant_images_3 restro_common2">
                  <img src={resorent4} alt="restorant_img_4" />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="restorant_images_3 restro_common2">
                  <img src={resorent5} alt="restorant_img_5" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="restorant_img_4 restro_common2">
                  <img src={resorent6} alt="restorant_img_6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="restorant_video_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="restorant_images_title_outer">
                <h4>Restaurant images</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  eiusmod tempor incididunt ut labore et dolore magna Eaque
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {GalleryData.map((e, i) => (
              <div className="col-lg-6" key={i}>
                <div className="restorant_video_gallery_img_outer">
                  <div
                    className="restorant_video_gallery_img troo_restro_video_common"
                    onClick={() => setModalData(e)}
                  >
                    <img src={e.img} alt="restorant_img_6" />
                  </div>
                  <div className="restorant_btn_hover">
                    <button className="videobtn" onClick={() => openModal(e)}>
                      <img src={icon} alt="youtube_icon" />
                      {modal ? (
                        <section className="modal__bg">
                          <div className="modal__align">
                            <div className="modal__content" modal={modal}>
                              <IoCloseOutline
                                className="modal__close"
                                arial-label="Close modal"
                                onClick={setModal}
                              />
                              <div className="modal__video-align">
                                <iframe
                                  width="560"
                                  height="400"
                                  src={modalData?.ved}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowfullscreen
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </section>
                      ) : null}
                    </button>
                    <p>Veiw Video</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default GalleryComponent;
