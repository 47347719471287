import React from 'react'
import Header from '../../Components/Header/Header'

import Footer from '../../Components/Footer/Footer'
import CommingCoonPage from '../../Components/CommingCoonPage/CommingCoonPage'
import BrandComponent from '../../Components/BrandComponent/BrandComponent'
import Mailbox from '../../Components/Footer/Mailbox'
import useDocumentTitle from '../../PageTitle'

const CommingSoon = () => {
  useDocumentTitle("Restaurant | Coming Sooon")
  return (
    <div>
        <Header/>
       <CommingCoonPage/>
       <BrandComponent/>
       <Mailbox/>
        <Footer/>
    </div>
  )
}

export default CommingSoon