import logo1 from "../../images/brand_Logo_1.png"
import logo2 from "../../images/brand_Logo_2.png"
import logo3 from "../../images/brand_Logo_3.png"
import logo4 from "../../images/brand_Logo_4.png"
import logo5 from "../../images/brand_Logo_5.png"
import logo6 from "../../images/brand_Logo_6.png"

export const BrandLogo = [
    
    {
        id : 1,
        img : logo1,
    },
    {
        id : 2,
        img : logo2,
    },
    {
        id : 3,
        img : logo3,
    },
    {
        id : 4,
        img : logo4,
    },
    {
        id : 5,
        img : logo5,
    },
    {
        id : 6,
        img : logo6,
    },
    {
        id : 7,
        img : logo1,
    },
    {
        id : 8,
        img : logo2,
    },
    {
        id : 9,
        img : logo3,
    },
    {
        id : 10,
        img : logo4,
    },
    {
        id : 11,
        img : logo5,
    },
    {
        id : 12,
        img : logo6,
    },
  
]