import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import BannerGlobal from "../Components/Banner/BannerGlobal";
import AboutComponent from "../Components/AboutComponent/AboutComponent";
import Highlights from "../Components/Highlights/Highlights";
import ClientReview from "../Components/ClientReview/ClientReview";
import TeamComponent from "../Components/TeamComponent/TeamComponent";
import Mailbox from "../Components/Footer/Mailbox";
import BrandComponent from "../Components/BrandComponent/BrandComponent";
import useDocumentTitle from "../PageTitle";

const About = () => {
  useDocumentTitle("Restaurant | About")
  return (
    <div className="menu2">
      <Header />
      <BannerGlobal />
      <AboutComponent />
      <Highlights />
      <ClientReview />
      <TeamComponent />
      <BrandComponent />
      <Mailbox />
      <Footer />
    </div>
  );
};

export default About;
