import React from "react";
import Header from "../../Components/Header/Header";
import BannerGlobal from "../../Components/Banner/BannerGlobal";
import Footer from "../../Components/Footer/Footer";
import ClientReview2 from "../../Components/ClientReview/ClientReview2";
import BlogComponent from "../../Components/BlogComponent/BlogComponent";
import BrandComponent from "../../Components/BrandComponent/BrandComponent";
import Mailbox from "../../Components/Footer/Mailbox";
import useDocumentTitle from "../../PageTitle";

const Testimonial = () => {
  useDocumentTitle("Restaurant | Testimonial")
  return (
    <div>
      <Header />
      <BannerGlobal />
      <ClientReview2 />
      <BlogComponent />
      <BrandComponent />
      <Mailbox />
      <Footer />
    </div>
  );
};

export default Testimonial;
