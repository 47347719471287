

export const BlogData = [
    {
        id : 1,
        name : "The most testy cake we’ve ever maed for you.",
        date : "27 Aug, 2022",
        desg : "By Admin",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt elit as ut and labore et dolore magna aliqua. Eaque ipsa quae",
    },
    {
        id : 2,
        name : "Join us for plant-powered january!",
        date : "26 Aug, 2022",
        desg : "By Admin",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt elit as ut and labore et dolore magna aliqua. Eaque ipsa quae",
    },
    {
        id : 3,
        name : "“I love the avocado toast and the California focaccia”",
        date : "27 Aug, 2023",
        desg : "By Admin",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt elit as ut and labore et dolore magna aliqua. Eaque ipsa quae",
    },
    {
        id : 4,
        name : "“Always worth the stop.”",
        date : "27 Aug, 2022",
        desg : "By Admin",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt elit as ut and labore et dolore magna aliqua. Eaque ipsa quae",
    },
    {
        id : 5,
        name : "“Im from the south, thats saying a lot…”",
        date : "27 Aug, 2022",
        desg : "By Admin",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt elit as ut and labore et dolore magna aliqua. Eaque ipsa quae",
    },
    {
        id : 6,
        name : "“Best breakfast on the east coast!”",
        date : "27 Aug, 2022",
        desg : "By Admin",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt elit as ut and labore et dolore magna aliqua. Eaque ipsa quae",
    },
    {
        id : 7,
        name : "“The absolute best red sauce”",
        date : "27 Aug, 2022",
        desg : "By Admin",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt elit as ut and labore et dolore magna aliqua. Eaque ipsa quae",
    },
    {
        id : 8,
        name : "“Their omelets are top-notch.”",
        date : "27 Aug, 2022",
        desg : "By Admin",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt elit as ut and labore et dolore magna aliqua. Eaque ipsa quae",
    },
    {
        id : 9,
        name : "“Don’t say I didn’t warn you…”",
        date : "27 Aug, 2022",
        desg : "By Admin",
        para : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt elit as ut and labore et dolore magna aliqua. Eaque ipsa quae",
    },
    
]