import React from 'react'
import "./ErrorPage.css"
import errorimg from "../../images/404.png"

const ErrorPage = () => {
  return (
    <section className="About_hero_wrapper content_404">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="about_content">
                        <h2>Oops!</h2>
                        <p>The page you looking for is dosen’t exit.</p>
                    </div>
                    <div className="img_404">
                        <img src={errorimg} alt="404"/>
                    </div>
                    <div className="btn_404">
                        <a href="./index.html"><button type="button" className="btn btn-primary">Go to Homepage</button></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ErrorPage