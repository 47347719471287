import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Banner from "../Components/Banner/Banner";
import AboutComponent from "../Components/AboutComponent/AboutComponent";
import Category from "../Components/Category/Category";
import Highlights from "../Components/Highlights/Highlights";
import MenuTable from "../Components/MenuTable/MenuTable";
import ClientReview from "../Components/ClientReview/ClientReview";
import TesteFeel from "../Components/TasteFeel_banner/TesteFeel";
import Form from "../Components/Form/Form";
import TeamComponent from "../Components/TeamComponent/TeamComponent";
import BlogComponent from "../Components/BlogComponent/BlogComponent";
import DownloadBanner from "../Components/DownloadBanner/DownloadBanner";
import FAQComponent from "../Components/FAQComponent/FAQComponent";
import BrandComponent from "../Components/BrandComponent/BrandComponent";
import Mailbox from "../Components/Footer/Mailbox";

const Home = () => {
  return (
    <div>
      <Header />
      <Banner />
      <AboutComponent />
      <Category />
      <Highlights />
      <MenuTable />
      <ClientReview />
      <TesteFeel />
      <Form />
      <TeamComponent/>
      <BlogComponent/>
      <DownloadBanner/>
      <FAQComponent/>
      <BrandComponent/>
      <Mailbox/>
      <Footer />
    </div>
  );
};

export default Home;
