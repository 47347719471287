import React from "react";
import "./Banner.css";
import banner from "../../images/Hero_img.png";

const Banner = () => {
  return (
    <section className="troo_da_hero_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="troo_da_hero_left_detail">
              <div className="hero_small_detail">
                <p>className the new flavour</p>
              </div>
              <div className="hero_title">
                <h1>
                  Geat food <span>with </span>
                  delightful experience
                </h1>
              </div>
              <div className="hero_content">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown.
                </p>
              </div>
              <div className="hero_btn_outer d-flex">
                <div className="btn-1">
                  <button type="button" className="btn btn-primary">
                    Our Menu
                  </button>
                </div>
                <div className="btn-2">
                  <button type="button" className="btn btn-primary">
                    About Us
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="troo_da_hero_right_img">
              <img src={banner} alt="Hero_img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
